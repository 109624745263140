import { Button } from '@mui/material'
import { URL_HOME } from 'config/config'
import React from 'react'

type Props = {
  handleClose: () => void
}
const VerificationRequestSubmitted = (props: Props) => {
  return (
    <>
      <div className="titleStepKYC">Verification in process</div>
      <div
        style={{
          maxWidth: '560px',
          margin: '30px auto',
          fontFamily: 'Roobert',
          textAlign: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          color: '#858580',
        }}
      >
        <p>Thank you!</p>
        <p>
          We have received your information and will process it asap. Expect to hear from us in less than 1 week. In the meantime,
          feel free to:
        </p>
        <p style={{ textAlign: 'left' }}>
          <ul>
            <li>Browse around your account and get ready to be amazed</li>
            <li>
              Check out our robust{' '}
              <a className="link" href={`${URL_HOME}/marketplace`} target="_blank" rel="noreferrer">
                SaaS Marketplace
              </a>
            </li>
          </ul>
        </p>
        <div style={{ maxWidth: '350px', margin: '0 auto' }}>
          <Button
            id="idButtonUpdateCompanie"
            color="primary"
            sx={{ width: '100%' }}
            variant="contained"
            size="medium"
            onClick={props.handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    </>
  )
}
export default VerificationRequestSubmitted
