import React from 'react'
import Icon from '@mui/material/Icon'
import { Badge } from '@mui/material'
import { gql } from '__generated__/gql'
import { useQuery } from '@apollo/client'

const QUERY = gql(/* GraphQL */ `
  query leadsConnection_SellerLeadsBadge(
    $companieId: String!
    $where: LeadWhereInput!
    $orderBy: LeadOrderByInput
    $first: Int
    $skip: Int
  ) {
    leadsConnection(companieId: $companieId, where: $where, orderBy: $orderBy, first: $first, skip: $skip) {
      count
    }
  }
`)

type Props = {
  companieId: string
  baseURL: string
}

const SellerLeadsBadge = (props: Props) => {
  const { data } = useQuery(QUERY, {
    variables: {
      companieId: props.companieId,
      where: { isArchived: false },
    },
  })
  return (
    <Badge badgeContent={data?.leadsConnection.count} color="primary">
      <Icon className={`iconDrawer ${props.baseURL === 'leads' && 'menuSelected'}`}>people_alt</Icon>
    </Badge>
  )
}

export default SellerLeadsBadge
