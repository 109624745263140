import React from 'react'
import { Modal, Slide, useMediaQuery, Theme } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import CreateIssuedCardForm from 'components/issuedCard/single/createIssuedCard/CreateIssuedCardForm'
import ActivatePreviewIssuedCardForm from 'components/issuedCard/single/createIssuedCard/ActivatePreviewIssuedCardForm'
import UserVerificationForm from 'components/onboarding/kyc/UserVerificationForm'
import ActivateSubscriptionForm from 'components/subscription/single/action/ActivateSubscriptionForm'
import ManageReviewDialog from 'components/review/single/ManageReviewDialog'
import CreateReviewDialog from 'components/review/single/CreateReviewDialog'
import BasicPlusDialogRight from 'components/companie/basicBlus/BasicPlusDialogRight'
import PremiumNoKYCDialogRight from 'components/companie/basicBlus/PremiumNoKYCDialogRight'

const DialogsProvider = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const parsed = queryString.parse(location.search)
  const dialogType = parsed.showDialog
  const open = parsed.showDialog !== undefined
  const handleClose = () => {
    const search = queryString.stringify({ ...parsed, showDialog: undefined })
    navigate('?' + search)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Slide in={open} direction="left">
        <div
          style={
            dialogType !== 'userVerification'
              ? {
                  background: 'white',
                  width: isMobile ? '100%' : '500px',
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  height: '100%',
                  overflow: 'scroll',
                }
              : {}
          }
        >
          {dialogType === 'userVerification' && <UserVerificationForm handleClose={handleClose} />}
          {dialogType === 'createCard' && <CreateIssuedCardForm />}
          {dialogType === 'activateCard' && <ActivatePreviewIssuedCardForm />}
          {dialogType === 'activateSubscription' && <ActivateSubscriptionForm />}
          {dialogType === 'createReview' && <CreateReviewDialog />}
          {/* {dialogType === 'editReview' && <EditReviewParent />} */}
          {dialogType === 'manageReview' && <ManageReviewDialog />}
          {dialogType === 'upgradeBasicPlus' && <BasicPlusDialogRight text="Upgrade to Basic Plus Account" />}
          {dialogType === 'upgradePremiumNoKYC' && <PremiumNoKYCDialogRight />}
        </div>
      </Slide>
    </Modal>
  )
}
export default DialogsProvider
