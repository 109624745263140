import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../../nav/error/Error'
import NotFound from '../../../nav/error/NotFound'
import Loading from '../../../nav/error/Loading'
import { gql } from '__generated__'
import { Companie, InvoiceOrderByInput, TypeInvoice } from '__generated__/graphql'
import SingleInvoiceListSellerPaymentFee from './SingleInvoiceListSellerPaymentFee'
import { Box } from '@mui/material'
import CreateInvoiceBuyerBasicPlus from 'components/companie/single/CreateInvoiceBuyerBasicPlus'
import SettingsCompaniePromoCodesBasicPlus from 'components/promoCode/list/SettingsCompaniePromoCodesBasicPlus'
// import RedeemPromoCode from '../RedeemPromoCode'

const QUERY = gql(/* GraphQL */ `
  query invoicesConnection_InvoicesListQueryBuyerBasicPlusPayment(
    $where: InvoiceWhereInput
    $skip: Int
    $orderBy: InvoiceOrderByInput
    $first: Int
  ) {
    invoicesConnection(orderBy: $orderBy, where: $where, first: $first, skip: $skip) {
      edges {
        node {
          id
          dateInvoice
          status
          currency
          productCostLocal
          type
          buyerFinalPrice
          subscription {
            id
          }
          product {
            id
            nameFile
            name
          }
          companie {
            id
          }
        }
      }
      aggregate {
        count
      }
    }
  }
`)

type Props = {
  page: number
  first: number
  companie: Pick<Companie, 'id'>
  onInvoiceCreated: () => void
}
const InvoicesListQueryBuyerBasicPlusPayment = (props: Props) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      where: {
        companieId: props.companie.id,
        type: { equals: TypeInvoice.RecuringPlatformFeesBasicPlus },
      },
      first: props.first,
      orderBy: InvoiceOrderByInput.DateInvoiceDesc,
      skip: (props.page - 1) * props.first,
    },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.invoicesConnection) return <NotFound />

  return (
    <>
      {data.invoicesConnection.edges.length === 0 ? (
        <div>
          <Box mt="10px">Amount: $99</Box>
          <Box mt="30px">
            <SettingsCompaniePromoCodesBasicPlus companieId={props.companie.id} />
          </Box>
          <Box mt="10px">
            <CreateInvoiceBuyerBasicPlus companieId={props.companie.id} onInvoiceCreated={props.onInvoiceCreated} />
          </Box>
        </div>
      ) : (
        <>
          <div style={{ height: 20 }} />
          <div className="paperOut">
            <div className="paperOut">
              <h3>Payment</h3>
            </div>
          </div>
          <div className="paperOut">
            {data.invoicesConnection.edges.map((invoiceNode) => (
              <div key={invoiceNode.node.id}>
                <SingleInvoiceListSellerPaymentFee invoice={invoiceNode.node} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default InvoicesListQueryBuyerBasicPlusPayment
