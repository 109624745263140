import { useAppContextWithCompany } from 'components/AppContext'
import React from 'react'
import OnboardingLogicCompanieSeller from '../companie/seller/OnboardingLogicCompanieSeller'
import KnowYourCustomerForm from '../KnowYourCustomerForm'
import KYCContextProvider from '../KYCContextProvider'
import SellerKYCContextProvider from './seller/SellerKYCContextProvider'
import MavenKYCForm from '../companie/maven/MavenKYCForm'
import MavenKYCContextProvider from './maven/MavenKYCContextProvider'
// import { useApolloClient } from '@apollo/client'

type Props = {
  handleClose: () => void
}

const UserVerificationForm = (props: Props) => {
  // const client = useApolloClient()
  const context = useAppContextWithCompany()
  const companyType = context.userRoleCompanie.companie.typeCompanie
  const handleClose = async () => {
    context.updateUserRoleCompanieViaQuery()
    // await client.resetStore()
    props.handleClose()
  }
  return (
    <>
      {companyType === 'SELLER' && (
        <SellerKYCContextProvider>
          <OnboardingLogicCompanieSeller handleClose={handleClose} />
        </SellerKYCContextProvider>
      )}
      {companyType === 'MAVEN' && (
        <MavenKYCContextProvider>
          <MavenKYCForm handleClose={handleClose} />
        </MavenKYCContextProvider>
      )}
      {companyType === 'BUYER' && (
        <KYCContextProvider>
          <KnowYourCustomerForm handleClose={handleClose} />
        </KYCContextProvider>
      )}
    </>
  )
}
export default UserVerificationForm
