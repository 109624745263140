import React from 'react'
import ListItem from '@mui/material/ListItem'
import { Link } from 'react-router-dom'
import Icon from '@mui/material/Icon'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useAppContextWithCompany } from '../../../AppContext'
import { useLocation } from 'react-router-dom'
import { ReactComponent as MemberIcon } from 'assets/icon/memberIcon.svg'
import { Box, Divider, SxProps } from '@mui/material'
import { Companie_DrawerLeftChildQuery } from '__generated__/graphql'
import SellerLeadsBadge from './SellerLeadsBadge'
import { ReactComponent as SubscriptionIcon } from 'assets/icon/subscriptionIcon.svg'
import { ReactComponent as PaymentSourceIcon } from 'assets/icon/paymentSourceIcon.svg'
import { ReactComponent as HomeIcon } from 'assets/icon/homeIcon.svg'
import { ReactComponent as SettingIcon } from 'assets/icon/settingIcon.svg'
import { ReactComponent as GrpahIcon } from 'assets/icon/grpahIcon.svg'
import { ReactComponent as MarketplaceIcon } from 'assets/icon/marketplaceIcon.svg'
import { ReactComponent as ProductSellerIcon } from 'assets/icon/productSellerIcon.svg'
import { ReactComponent as PaymentsSellerIcon } from 'assets/icon/paymentsSellerIcon.svg'
import { ReactComponent as ReviewIcon } from 'assets/icon/reviewIcon.svg'
import { ReactComponent as MyPerkOfferIcon } from 'assets/icon/myPerkOfferIcon.svg'
import { ReactComponent as ProductChatIcon } from 'assets/icon/productChatIcon.svg'
import { ReactComponent as PartnerIcon } from 'assets/icon/partner.svg'
import { ReactComponent as GetStartedIcon } from 'assets/icon/getStartedIcon.svg'
import { Permission } from '__generated__/graphql'

type Props = {
  companie: NonNullable<Companie_DrawerLeftChildQuery['companie']>
}

const DrawerLeftSeller = (props: Props) => {
  const context = useAppContextWithCompany()
  const location = useLocation()
  const pathnames = location.pathname.split('/')
  let baseURL = ''
  if (pathnames.length > 1) {
    baseURL = pathnames[1]
  }

  if (pathnames.length > 2) {
    if (pathnames[1] === 'seller') {
      baseURL = pathnames[2]
    }
  }

  const marginDidivder = { marginTop: 1, marginBottom: 1 }

  const listItemStyle: React.CSSProperties = {
    fontWeight: 'bold',
    fontSize: '0.78rem !important',
    height: 42,
    marginBottom: '0px !important',
    borderRadius: 20,
  }

  const listItemStyleSelected: React.CSSProperties = {
    backgroundColor: '#2475ff',
    color: 'white',
    fill: 'white',
  }
  const listItemStyleNotSelected: SxProps = {
    '&:hover': {
      color: '#1445fe!important',
      backgroundColor: '#faf8f7',
      fill: '#1445fe',
    },
  }

  const columnMenuDrawer: React.CSSProperties = {
    marginTop: 10,
    marginRight: 20,
    marginLeft: 20,
    height: '100%',
  }

  const listItemIconClass: React.CSSProperties = {
    marginLeft: 8,
    minWidth: 35,
  }

  const fontListItemText: React.CSSProperties = {
    fontSize: '0.75rem',
    fontWeight: 400,
  }
  return (
    <div style={columnMenuDrawer}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Link to="/">
          <ListItem
            id="ldrawer-home-seller"
            sx={{
              ...listItemStyle,
              ...(baseURL === '' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
            onClick={() => context.setDrawerLeftMobile(false)}
          >
            <ListItemIcon style={listItemIconClass}>
              <HomeIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Home</div>
          </ListItem>
        </Link>

        {context.userRoleCompanie.permissions &&
          context.userRoleCompanie.permissions.includes(Permission.CanSeeUsersInCompanie) && (
            <Link to={`/seller/team/${props.companie.id}`}>
              <ListItem
                id="ldrawer-team-seller"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'team' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <MemberIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Members</div>
              </ListItem>
            </Link>
          )}
        <Divider sx={marginDidivder} />
        {props.companie.ownedProducts.map((product, i: number) => (
          <div key={product.id}>
            <Link to={`/seller/product/${product.id}`}>
              <ListItem
                id="ldrawer-product-seller"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'product' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <ProductSellerIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Product Page</div>
              </ListItem>
            </Link>
            {props.companie.isProductEnabledForSeller && (
              <Link to={`/seller/subscribers/${product.id}`}>
                <ListItem
                  id="ldrawer-subscribers-seller"
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'subscribers' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <SubscriptionIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Subscribers</div>
                </ListItem>
              </Link>
            )}

            {props.companie.isProductEnabledForSeller && (
              <Link to={`/seller/payments/${product.id}`}>
                <ListItem
                  id={'id-ldrawer-payments-seller-' + i}
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'payments' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <PaymentsSellerIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Payments</div>
                </ListItem>
              </Link>
            )}

            {props.companie.isPerkEnabledForSeller && (
              <Link to={`/seller/perks/${product.id}`}>
                <ListItem
                  id={'id-ldrawer-perks-seller-' + i}
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'perks' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <MyPerkOfferIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>My Perk Offer</div>
                </ListItem>
              </Link>
            )}
            {props.companie.isProductEnabledForSeller && (
              <Link to={`/seller/revenueShare/${props.companie.id}/${product.id}`}>
                <ListItem
                  id="ldrawer-revenueShare-seller"
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'revenueShare' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <Icon className={`  iconDrawer ${baseURL === 'revenueShare' && 'menuSelected'}`}>monetization_on</Icon>
                  </ListItemIcon>
                  <div style={fontListItemText}>Revenue Share</div>

                  {/* <ListItemIcon className="listItemIconClass">
                    <Icon className={`  iconDrawer ${baseURL === 'revenueShare' && 'menuSelected'}`}>monetization_on</Icon>
                  </ListItemIcon>
                  <ListItemText classes={{ primary: 'menuDrawerLeft ' }} primary="Revenue Share" /> */}
                </ListItem>
              </Link>
            )}
            {props.companie.isProductEnabledForSeller && (
              <Link to="/seller/leads/">
                <ListItem
                  id="ldrawer-leads-seller"
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'leads' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <SellerLeadsBadge companieId={props.companie.id} baseURL={baseURL} />
                  </ListItemIcon>
                  <div style={fontListItemText}>Wingman</div>
                </ListItem>
              </Link>
            )}

            {props.companie.showGoogleAnalyticsGraph && (
              <Link to={`/seller/analytics/${product.id}`}>
                <ListItem
                  id="ldrawer-analytics-seller"
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'analytics' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <GrpahIcon width={16} />
                  </ListItemIcon>
                  <div style={fontListItemText}>Analytics</div>
                </ListItem>
              </Link>
            )}
            {props.companie.isProductEnabledForSeller && (
              <Link to="/seller/reviewRequest">
                <ListItem
                  id="ldrawer-reviewRequest-seller"
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'reviewRequest' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <ReviewIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Reviews</div>
                </ListItem>
              </Link>
            )}

            <Link to="/seller/marketingAssets">
              <ListItem
                id="ldrawer-marketingAssets-seller"
                onClick={() => context.setDrawerLeftMobile(false)}
                sx={{
                  ...listItemStyle,
                  ...(baseURL === 'marketingAssets' ? listItemStyleSelected : listItemStyleNotSelected),
                }}
              >
                <ListItemIcon style={listItemIconClass}>
                  <GetStartedIcon />
                </ListItemIcon>
                <div style={fontListItemText}>Marketing Assets</div>
              </ListItem>
            </Link>
            {props.companie.isProductEnabledForSeller && (
              <Link to="/seller/servicePartners">
                <ListItem
                  id="ldrawer-servicePartners-seller"
                  onClick={() => context.setDrawerLeftMobile(false)}
                  sx={{
                    ...listItemStyle,
                    ...(baseURL === 'servicePartners' ? listItemStyleSelected : listItemStyleNotSelected),
                  }}
                >
                  <ListItemIcon style={listItemIconClass}>
                    <PartnerIcon />
                  </ListItemIcon>
                  <div style={fontListItemText}>Service Partners</div>
                </ListItem>
              </Link>
            )}
          </div>
        ))}
        <Divider sx={marginDidivder} />

        <Link to="/settings/company">
          <ListItem
            id="ldrawer-company-seller"
            onClick={() => context.setDrawerLeftMobile(false)}
            sx={{
              ...listItemStyle,
              ...(baseURL === 'settings' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
          >
            <ListItemIcon style={listItemIconClass}>
              <SettingIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Settings</div>
          </ListItem>
        </Link>
        {props.companie.isProductEnabledForSeller && context.userRoleCompanie.permissions.includes(Permission.CanSeeCards) && (
          <Link to={'/seller/paymentSource/' + props.companie.id}>
            <ListItem
              id="ldrawer-paymentSource-seller"
              sx={{
                ...listItemStyle,
                ...(baseURL === 'paymentSource' ? listItemStyleSelected : listItemStyleNotSelected),
              }}
              onClick={() => context.setDrawerLeftMobile(false)}
            >
              <ListItemIcon style={listItemIconClass}>
                <PaymentSourceIcon />
              </ListItemIcon>
              <div style={fontListItemText}>Payment Source</div>
            </ListItem>
          </Link>
        )}
        <Link to="/marketplace">
          <ListItem
            id="ldrawer-marketplace-seller"
            onClick={() => context.setDrawerLeftMobile(false)}
            sx={{
              ...listItemStyle,
              ...(baseURL === 'marketplaceBtoB' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
          >
            <ListItemIcon style={listItemIconClass}>
              <MarketplaceIcon />
            </ListItemIcon>
            <div style={fontListItemText}>SaaS & Service Deals</div>
          </ListItem>
        </Link>
        <Link to="/messages/">
          <ListItem
            id="messages"
            sx={{
              ...listItemStyle,
              ...(baseURL === 'messages' ? listItemStyleSelected : listItemStyleNotSelected),
            }}
            onClick={() => context.setDrawerLeftMobile(false)}
          >
            <ListItemIcon style={listItemIconClass}>
              <ProductChatIcon />
            </ListItemIcon>
            <div style={fontListItemText}>Messages</div>
          </ListItem>
        </Link>
      </Box>
    </div>
  )
}

export default DrawerLeftSeller
