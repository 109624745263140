import React from 'react'
import { useQuery } from '@apollo/client'
import Error from '../../nav/error/Error'
import Loading from '../../nav/error/Loading'
import NotFound from '../../nav/error/NotFound'
import { useAppContextWithCompany } from 'components/AppContext'
import { gql } from '__generated__'
import SourcesQueryPremiumNoKYC from './SourcesQueryPremiumNoKYC'

const QUERY = gql(/* GraphQL */ `
  query companie_PaymentSourceBuyerBasicPlusSettings($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
      hideAddBank
      requestCardVerification
      paymentType
      addPaypal
      addStripeBank
      incomingPaymentFeeACHCard
      hideDebitCard
      incomingPaymentFeeDebitCard
      hideDebitCredit
      incomingPaymentFeeCreditCard
    }
  }
`)
type Props = {
  onInvoiceCreated: () => void
}
const PaymentSourceBuyerPremiumNoKYCDialogRight = (props: Props) => {
  const { userRoleCompanie } = useAppContextWithCompany()

  const { loading, error, data } = useQuery(QUERY, {
    variables: { where: { id: userRoleCompanie.companie.id } },
  })

  if (error) return <Error message={error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : ''} />
  if (loading) return <Loading />
  if (!data?.companie) return <NotFound />
  const companie = data.companie

  return <SourcesQueryPremiumNoKYC companie={companie} onInvoiceCreated={props.onInvoiceCreated} />
}
export default PaymentSourceBuyerPremiumNoKYCDialogRight
