import React from 'react'
import LogoutIcon from '@mui/icons-material/Logout'
import { Button, Paper, Theme, useMediaQuery } from '@mui/material'
import NotFound from 'components/nav/error/NotFound'
import Error from 'components/nav/error/Error'
import { useAppContextWithCompany } from 'components/AppContext'
import { useQuery } from '@apollo/client'
import PersonalAddresssForm from 'components/onboarding/PersonalAddresssForm'
import AdditionalDetailsForm from './AdditionalDetailsForm'
import OnboardingCompanieForm from './companie/buyer/OnboardingCompanieForm'
import KYCStepsProgress from './KYCStepsProgress'
import { KYCStep, useKYCContext } from './KYCContext'
import CompanieAddresssForm from './CompanieAddressForm'
import BeneficiaryDetailsForm from './BeneficiaryDetailsForm'
import SubmitVerificationForm from './SubmitVerificationForm'
import OnboardingValidaterPhoneUserQuery from './OnboardingValidaterPhoneUserQuery'
import { gql } from '__generated__'
import VerificationRequestSubmitted from './VerificationRequestSubmitted'
import { URL_HOME } from 'config/config'
import LeadershipDocumentsForm from './LeadershipDocumentsForm'
import LeadershipIdNumber from './LeadershipIdNumber'
import OnboardingCompanieType from './companie/buyer/OnboardingCompanieType'
import { ReactComponent as FilledProgressCircle } from '../../assets/icon/filledProgressCircle.svg'
import { ReactComponent as TimeIcon } from '../../assets/icon/clock.svg'
import { ReactComponent as FirewallIcon } from '../../assets/icon/firewall.svg'

const USER_QUERY = gql(/* GraphQL */ `
  query user_KnowYourCustomerForm($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      firstName
      lastName
      last4Social
      firstNameTemp
      lastNameTemp
      linkedInLink
      dobYear
      dobMonth
      dobDay
      verificationStatus
      userRoleCompanies {
        id
      }
    }
  }
`)

const COMPANY_QUERY = gql(/* GraphQL */ `
  query companie_KnowYourCustomerForm($where: CompanieWhereUniqueInput!) {
    companie(where: $where) {
      id
      name
      mcc
      registeredBusinessName
      website
      linkedin
      registrationNumber
      typeBusinessStructure
      leadershipEmail
      typeCreation
      leadershipFirstName
      leadershipLastName
      leadershipPhone
      leadershipPhoneCode
      leadershipTitle
      leadershipIsOwner
      leadershipIsExecutive
      leadershipIsDirector
      leadershipPercentOwnership
      leadershipDobDay
      leadershipDobYear
      leadershipDobMonth
      leadershipIdNumberToken
      leadershipLast4
      statusVerification
      companyPhone
      companyPhoneCode
      emailCompany
      stripeCompanyStructure
      stripeBusinessType
    }
  }
`)

interface Props {
  handleClose: () => void
}
const KnowYourCustomerForm = (props: Props) => {
  const context = useAppContextWithCompany()
  const kycContext = useKYCContext()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const [start, setStart] = React.useState(false)
  const userQuery = useQuery(USER_QUERY, {
    variables: { where: { id: context.me.id } },
  })

  const companyQuery = useQuery(COMPANY_QUERY, {
    variables: { where: { id: context.userRoleCompanie.companie.id } },
  })

  if (userQuery.error)
    return <Error message={userQuery.error.graphQLErrors.length > 0 ? userQuery.error.graphQLErrors[0].message : ''} />
  if (companyQuery.error)
    return <Error message={companyQuery.error.graphQLErrors.length > 0 ? companyQuery.error.graphQLErrors[0].message : ''} />
  if (userQuery.loading || companyQuery.loading) return <></>
  if (!userQuery.data?.user || !companyQuery.data?.companie) return <NotFound />

  const user = userQuery.data.user
  const userRoleCompanie = user.userRoleCompanies.find((userRoleCompanie) => userRoleCompanie.id === context.userRoleCompanie.id)
  if (!userRoleCompanie) return <NotFound />
  const companie = companyQuery.data.companie

  const nextStep = () => {
    companyQuery.refetch()
    userQuery.refetch()
    kycContext.nextStep()
  }

  const setStep = (step: KYCStep, force?: boolean) => {
    companyQuery.refetch()
    userQuery.refetch()
    kycContext.setCurrentStep(step, force)
  }

  const descriptionStyle = {
    margin: '10px auto',
    fontFamily: 'Roobert',
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#858580',
  } as const

  const KYCPages = (
    <>
      {start ? (
        <>
          {kycContext.currentStep() === 'personalPhoneNumber' && (
            <OnboardingValidaterPhoneUserQuery userId={context.me.id} onUpdate={nextStep} />
          )}
          {kycContext.currentStep() === 'personalAddress' && (
            <PersonalAddresssForm userId={user.id} onCreate={nextStep} onUpdate={nextStep} onCancel={kycContext.previousStep} />
          )}
          {kycContext.currentStep() === 'personalDetails' && (
            <AdditionalDetailsForm user={user} userRoleCompanie={userRoleCompanie} onUpdate={nextStep} />
          )}
          {kycContext.currentStep() === 'companyType' && (
            <OnboardingCompanieType
              companie={companie}
              onCreate={() => setStep('leadershipDocuments', true)}
              onUpdate={nextStep}
              onCancel={kycContext.previousStep}
            />
          )}
          {kycContext.currentStep() === 'companyAddress' && (
            <CompanieAddresssForm
              companieId={companie.id}
              userId={user.id}
              onCreate={nextStep}
              onUpdate={nextStep}
              onCancel={kycContext.previousStep}
            />
          )}
          {kycContext.currentStep() === 'companyDetails' && (
            <OnboardingCompanieForm onUpdate={nextStep} onCancel={kycContext.previousStep} companie={companie} />
          )}
          {kycContext.currentStep() === 'beneficiaryDetails' && (
            <BeneficiaryDetailsForm onCreate={nextStep} onCancel={kycContext.previousStep} userId={user.id} companie={companie} />
          )}
          {kycContext.currentStep() === 'leadershipDocuments' && (
            <LeadershipDocumentsForm companieId={companie.id} onCreate={nextStep} />
          )}
          {kycContext.currentStep() === 'leadershipIdNumber' && (
            <LeadershipIdNumber companie={companie} onUpdate={nextStep} onCancel={kycContext.previousStep} />
          )}
          {kycContext.currentStep() === 'submitForm' && <SubmitVerificationForm onUpdate={nextStep} />}
          {kycContext.currentStep() === 'requestSent' && <VerificationRequestSubmitted handleClose={props.handleClose} />}
        </>
      ) : (
        <>
          {['ADMIN', 'OWNER'].includes(context.userRoleCompanie.companieRole) ? (
            <>
              <div className="titleStepKYC">Account Verification</div>
              <div style={descriptionStyle}>This KYC is required by our banking partner.</div>
              <div className="titleStepKYC" style={{ fontSize: '16px', marginTop: '40px' }}>
                What you need to prepare:
              </div>
              <div style={{ width: 'fit-content', margin: '10px auto 30px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FilledProgressCircle />
                  <span style={{ marginLeft: '15px', fontSize: '14px' }}>A scan of a government issued ID</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FilledProgressCircle />
                  <span style={{ marginLeft: '15px', fontSize: '14px' }}>Your Social Security Number (SSN)</span>
                </div>
              </div>
              <div style={{ maxWidth: '250px', margin: '50px auto 30px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <TimeIcon />
                  <div
                    style={{
                      maxWidth: '120px',
                      textAlign: 'center',
                      marginTop: '20px',
                      color: '#858580',
                    }}
                  >
                    This will take up to <b>10 minutes</b>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FirewallIcon />
                  <div
                    style={{
                      maxWidth: '120px',
                      textAlign: 'center',
                      marginTop: '20px',
                      color: '#858580',
                    }}
                  >
                    Your information will be kept <b>private</b>
                  </div>
                </div>
              </div>
              <div style={{ maxWidth: '350px', margin: '0 auto' }}>
                <Button
                  id="idButtonUpdateCompanie"
                  color="primary"
                  sx={{ width: '100%' }}
                  variant="contained"
                  size="medium"
                  onClick={() => setStart(true)}
                >
                  Let{`'`}s go!
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="titleStepKYC">Account Verification</div>
              <div style={descriptionStyle}>This KYC is required by our banking partner.</div>

              <div className="titleStepKYC" style={{ fontSize: '16px', margin: '30px auto', maxWidth: '400px' }}>
                You don't have permission to fill this KYC. Please ask an account admin to complete it.
              </div>
              <div style={{ maxWidth: '350px', margin: '0 auto' }}>
                <Button
                  id="idButtonUpdateCompanie"
                  color="primary"
                  sx={{ width: '100%' }}
                  variant="contained"
                  size="medium"
                  onClick={props.handleClose}
                >
                  Close
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
  return (
    <div
      style={{
        width: isMobile ? 'unset' : '1100px',
        maxWidth: '100vw',
        height: '100vh',
        background: '#FAF8F7',
        position: 'absolute',
        right: 0,
        top: 0,
      }}
    >
      <div
        style={{
          width: '100%',
          background: '#2475FF',
          height: '100px',
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 35px',
          alignItems: 'center',
          boxSizing: 'border-box',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
        }}
      >
        <div>
          <div style={{ fontSize: '21px' }}>Account verification</div>
        </div>
        <Button
          onClick={props.handleClose}
          variant="outlined"
          sx={{ fontFamily: 'Roobert', fontSize: 16, borderRadius: '20px', lineHeight: 1 }}
        >
          <LogoutIcon style={{ height: '18px', marginRight: '5px' }} />
          {kycContext.filledSteps['submitForm'] ? 'Close' : 'Continue later'}
        </Button>
      </div>
      <div style={{ width: '100%', overflow: 'scroll', height: '100vh' }}>
        <div
          style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', padding: '38px', paddingTop: '140px' }}
        >
          {isMobile ? (
            <div>{KYCPages}</div>
          ) : (
            <Paper variant="outlined" className="paperIn" style={{ background: 'white', paddingTop: '60px', flex: 1 }}>
              {KYCPages}
            </Paper>
          )}
          <div>
            {isMobile ? (
              <></>
            ) : (
              <>
                <KYCStepsProgress stripeBusinessType={companie.stripeBusinessType} />
                <Button
                  variant="outlined"
                  sx={{ fontFamily: 'Roobert', borderRadius: '20px', margin: '50px 0 0 50px' }}
                  target="_blank"
                  href={`${URL_HOME}/support-call`}
                >
                  Request a video call
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowYourCustomerForm
